.recipeImage {
  width: 150px;
  height: 150px;
  object-fit: contain;
  flex-shrink: 0;
  margin-right: 2rem;

  &Placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.03);
  }

  &Icon {
    color: rgba(0, 0, 0, 0.33);
    font-size: 4rem;
  }
}
