html {
  font-size: 10px;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  height: 100%;
}
#root {
  height: 100%;
}

p {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.MuiInputLabel-root {
  font-size: 1.2rem !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input,
  .MuiSelect-select {
    font-size: 1.6rem !important;
  }
}
